/* eslint-disable-next-line @typescript-eslint/no-empty-function */
const doNothing = () => {};

/* eslint-disable-next-line no-restricted-globals */
self.addEventListener('install', doNothing);

/* eslint-disable-next-line no-restricted-globals */
self.addEventListener('activate', doNothing);

/* eslint-disable-next-line no-restricted-globals */
self.addEventListener('fetch', doNothing);
